.mainWrapper {
    display: flex;
    justify-content: center;
}

.mainContainer {
    height: 100%;
    width: 100%;
}

.contentContainer {
    max-width: 680px;
    margin: 0 auto;
}

.header {
    margin-bottom: 30px;
}

.title {
    height: 40px;
    background-color: var(--skeleton-color);
}

.headerInfo {
    margin: 12px 0;
    display: flex;
    align-items: center;
}

.info {
    margin-left: 12px;
    height: 28px;
    width: 40%;
    background-color: var(--skeleton-color);
}

.authorAvatarContainer {
    width: 28px;
    height: 28px;
    font-size: 1rem;
    display: flex;
    overflow: hidden;
    position: relative;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    line-height: 1;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    color: white;
    background-color: var(--skeleton-color);
}

.content {
    margin-bottom: 30px;
}

.contentLine {
    margin-bottom: 12px;
    height: 28px;
    width: 100%;
    background-color: var(--skeleton-color);
}

.shorterContentLine {
    margin-bottom: 12px;
    height: 28px;
    width: 50%;
    background-color: var(--skeleton-color);
}
