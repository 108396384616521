.tagContainer {
    display: flex;
    align-items: center;
    padding: 4px 6px;
    border-radius: 24px;
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.selected {
    transition: 1s;
}

.notSelected {
    opacity: 0.5;
}

.tagIcon {
    height: 14px;
    width: 14px;
    margin-right: 4px;
}

.tagText {
    font-size: 12px;
}

/* SmAndUp */
@media only screen and (min-width: 600px) {
    .tagContainer {
        padding: 5px 10px;
    }

    .tagIcon {
        height: 16px;
        width: 16px;
        margin-right: 4px;
    }

    .tagText {
        font-size: 14px;
    }
}
