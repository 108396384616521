.cardContainer {
    display: flex;
    max-width: 100%;
    min-height: 100px;
    margin: 8px 0;
    cursor: pointer;
    border-radius: 4px;
    padding: 8px;
    transition: 0.3s;
    text-decoration: none;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;

    /* Not sure about shadow effect, maybe just get rid of it? */
    /*
    box-shadow: 0 1px 1px rgba(185, 185, 185, 0.08),
            0 2px 2px rgba(185, 185, 185, 0.12),
            0 4px 4px rgba(185, 185, 185, 0.16),
            0 8px 8px rgba(185, 185, 185, 0.20);
    */
}

.cardContainer:hover {
    background-color: rgb(0, 0, 0, 0.03);
}

.contentContainer {
    padding-right: 20px;
    color: black;
}

.thumbnailContainer {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
    margin-left: auto;
}

.thumbnail {
    width: 100%;
    height: 100%;
    font-size: 12px;
    color: rgb(117, 117, 117);
}

.title {
    font-weight: 700;
    letter-spacing: 0px;
    font-size: 16px;
    color: rgb(41, 41, 41);
    line-height: 20px;
}

.bottom {
    display: flex;
    color: rgb(117, 117, 117);
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
}

.publishedDate {
    margin-right: 8px;
}

.tagsContainer {
    margin-top: 8px;
    display: flex;
    align-items: center;
}

.tagIcon {
    margin-right: 4px;
    color: rgb(117, 117, 117);
}

/* SmAndUp */
@media only screen and (min-width: 600px) {
    .cardContainer {
        max-width: 100%;
        min-height: 140px;
        padding: 16px;
    }

    .thumbnailContainer {
        min-width: 140px;
        max-width: 140px;
        min-height: 140px;
        max-height: 140px;
        margin-left: auto;
    }

    .title {
        font-size: 22px;
        line-height: 26px;
    }

    .bottom {
        font-size: 16px;
        line-height: 20px;
    }

    .publishedDate {
        margin-right: 12px;
    }

    .tagsContainer {
        margin-top: 8px;
        display: flex;
        align-items: center;
        height: 20px;
    }

    .tagIcon {
        height: 16px;
        width: 16px;
    }
}
