.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container h2 {
    text-align: center;
}

.formContainer {
    margin-top: 16px;
    width: 100%;
    max-width: 400px;
}

.emailContainer {
    display: flex;
    align-items: center;
}

.emailSubmitBtn {
    border-radius: 10px;
    margin-left: 6px;
    height: 24px;
    width: 24px;
    padding: 4px;
    transition: 400ms;
    cursor: pointer;
}

.emailSubmitBtn:hover {
    background: rgb(0, 0, 0, 0.075);
}

.email {
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 32px;
    padding-left: 10px;
    outline: none;
    transition: 1s;
    background-color: rgb(249, 249, 249);
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
}

.email:focus {
    border-color: #aaa;
    color: #000;
    background-color: #fff;
}

.statusMessage {
    font-size: 14px;
    margin-left: 4px;
}

.emailSuccess {
    color: var(--success-color);
}

.emailError {
    color: var(--error-color);
}

.subscriptionsContainer ul {
    list-style: none;
    padding: 0;
}

.subscriptionsContainer li {
    padding: 2px 0 !important;
    display: flex;
    align-items: center;
}

.subscriptionsContainer li::before {
    display: none;
}
