.container {
    margin: 0 auto;
    max-width: 780px;
    padding-bottom: 15px;
}

.searchIcon {
    margin: 0 0 12px 6px;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.searchBarContainer {
    margin-bottom: 20px;
    padding: 0 12px;
    width: 100%;
}

.searchBar {
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 28px;
    padding-left: 10px;
    outline: none;
    transition: 1s;
    background-color: rgb(249, 249, 249);
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
}

.searchBar:focus {
    border-color: #aaa;
    color: #000;
    background-color: #fff;
}

.tagsContainer {
    margin: 14px auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    min-height: 20px;
    max-width: 95%;
}

.tagContainer {
    margin: 4px auto;
    display: flex;
    align-items: center;
}

.tagSpacer {
    padding: 0 6px;
}

.noBlogs {
    margin-left: 8px;
}

/* SmAndUp */
@media only screen and (min-width: 600px) {
    .tagContainer {
        margin: 4px 0;
    }

    .searchIcon {
        margin: 0 0 12px 16px;
        height: 30px;
        width: 30px;
        cursor: pointer;
    }

    .searchBar {
        height: 38px;
    }

    .noBlogs {
        margin-left: 20px;
        font-size: 24px;
    }
}

.embedContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.testEmbed {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
