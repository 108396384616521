.container {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    box-sizing: border-box;
    width: 100%;
    background-color: #121212;
    color: white;
    min-height: 100px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.infoContainer {
    margin-right: 20px;
}

.text {
    display: block;
    font-size: 14px;
}

.links {
    display: block;
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 300;
}

.links a:first-child {
    margin-right: 10px;
}

.links a {
    color: white;
}

.switchesContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.switchesContainer label {
    display: flex;
    align-items: center;
    padding-top: 8px;
}

.switchesContainer label:not(:last-child) {
    margin-right: 16px;
}

.switchesContainer label span{
    margin-left: 10px;
}

.submitContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

.submitContainer button {
    min-width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    transition: 200ms;
    cursor: pointer;
    background-color: rgb(230, 230, 230);
}

.submitContainer button:first-child {
    margin-bottom: 10px;
    opacity: 0.85;
    background-color: rgb(226, 226, 226);
}

.submitContainer button:hover {
    background-color: rgb(255, 255, 255);
}

/* SmAndUp */
@media only screen and (min-width: 600px) {
    .container {
        flex-direction: row;
    }

    .submitContainer button {
        min-width: 120px;
    }

    .submitContainer {
        padding: 20px 30px;
    }
}

/* MdAndUp */
@media only screen and (min-width: 960px) {
    .container {
        padding: 20px 40px;
    }

    .submitContainer {
        flex-direction: row;
    }

    .submitContainer button:first-child {
        margin-bottom: 0;
        margin-right: 10px;
    }

    .switchesContainer {
        margin-top: 14px;
    }

    .text {
        font-size: 16px;
    }

    .links {
        margin-top: 6px;
        font-size: 14px;
    }
}
