.mainContentWrapper {
    min-height: 100vh;
}

.navBar {
    padding: 0 12px;
    margin-bottom: 12px;
    background-color: white;
    height: 88px;
    display: flex;
}

.homeLink {
    margin: 32px auto 0 auto;
    height: 26px;
}

.homeContainer {
    height: 26px;
    width: 200px;
}

.homeWrapper {
    cursor: pointer;
    position: relative;
    height: 0;
    width: 100%;
    padding: 0;
}

.mainContainer {
    min-height: 100%;
}

.mainContent {
    height: 96%;
    padding: 5px 24px;
    background-color: white;
}

.notFoundContainer {
    max-width: 680px;
    margin: 0 auto;
}
