.container {
    padding: 10px 0 20px;
    width: 100%;
    background-color: #121212;
    color: white;
    text-align: center;
}

.socialMediaContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    height: 30px;
}

.socialMediaWrapper {
    height: 30px;
    width: 30px;
}

.linksContainer {
    margin-bottom: 5px;
}

.bottomLink {
    font-size: 12px;
    text-decoration: none;
    color: #ddd;
    margin: 0 5px;
}

.bottomLink:hover {
    text-decoration: underline;
}

.maethril {
    font-size: 0.8em;
    opacity: 0.2;
}

/* SmAndUp */
@media only screen and (min-width: 860px) {
    .bottomLink {
        margin: 0 30px;
    }

    .container {
        display: flex;
        align-items: center;
        padding: 16px 40px;
    }

    .linksContainer {
        margin-left: auto;
        margin-bottom: 0px;
    }

    .maethril {
        margin-top: 3px;
    }

    .socialMediaContainer {
        justify-content: left;
        align-items: center;
        margin-bottom: 0px;
    }
}
