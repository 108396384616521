.cardContainer {
    display: flex;
    max-width: 100%;
    min-height: 100px;
    margin: 8px 0;
    border-radius: 4px;
    padding: 8px;

    /* Not sure about shadow effect, maybe just get rid of it? */
    /*
    box-shadow: 0 1px 1px rgba(185, 185, 185, 0.08),
            0 2px 2px rgba(185, 185, 185, 0.12),
            0 4px 4px rgba(185, 185, 185, 0.16),
            0 8px 8px rgba(185, 185, 185, 0.20);
    */
}

.contentContainer {
    padding-right: 20px;
}

.thumbnailContainer {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
    margin-left: auto;
    background-color: var(--skeleton-color);
}

.title {
    min-width: 150px;
    max-width: 250px;
    height: 30px;
    margin-bottom: 10px;
    border: 4px;
    background-color: var(--skeleton-color);
}

.bottom {
    width: 60%;
    height: 20px;
    border: 4px;
    background-color: var(--skeleton-color);
}

