.button {
    color: rgba(0, 0, 0, 0.8);
    position: fixed;
    bottom: 0px;
    right: 0px;
    margin: 20px;
    padding: 14px;
    border-radius: 50%;
    border: none;
    background-color: #DDD;
    cursor: pointer;
    transition: 100ms;
}

.buttonIcon {
    height: 22px;
    width: 22px;
    opacity: 0.6;
    display: flex;
    align-items: center;
}

.button:hover {
    color: rgba(0, 0, 0, 1);
}
