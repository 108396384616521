.container {
    padding: 14px !important;
}

.container:not(:last-child) {
    border-bottom: 1px solid rgb(211, 211, 211);
}

.container::before {
    display: none;
}

.container dl {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-position: outside;
    list-style-type: none;
    line-height: 1.25;
    text-indent: 0;
}

.container dt:first-of-type,
.container dd:first-of-type {
    border-top-width: 0;
    margin-top: 0;
    padding-top: 0;
}

.container dt {
    border: 0;
    clear: both;
    display: block;
    flex: 0 1 30%;
    font-size: 0.8em;
    font-weight: 700;
    line-height: 1.25;
    margin: 0 0 .5em;
    padding: 0;
    text-indent: 0;
}

.container dd {
    flex: 0 1 70%;
    font-size: 0.8em;
    line-height: 1.25;
    margin: 0 0 .5em;
    padding: 0;
    font-weight: 300;
    text-indent: 0;
}
