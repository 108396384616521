.clickable {
    cursor: pointer;
    text-decoration: underline;
}

.mainWrapper {
    display: flex;
    justify-content: center;
}

.mainContainer {
    height: 100%;
    width: 100%;
}

.contentContainer {
    max-width: 680px;
    margin: 0 auto;
}

.header {
    margin-bottom: 30px;
}

.header h1 {
    letter-spacing: -0.016em;
    line-height: 40px;
    font-size: 32px;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    color: rgb(41, 41, 41);
    font-weight: 700;
    overflow-wrap: break-word;
    word-break: break-word;
}

.headerInfo {
    margin: 12px 0;
    display: flex;
    align-items: center;
}

.info {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.authorName {
    color: var(--primary-color);
    cursor: pointer;
}

.authorTooltip {
    opacity: 1 !important;
    padding: 12px 20px 12px 12px !important;
    background-color: white !important;
    border: 1px solid rgb(230, 230, 230) !important;
    box-shadow: rgb(230, 230, 230) 0px 1px 4px;
}

.authorTooltip::before {
    border-right: 8px solid rgb(230, 230, 230) !important;
}

.authorTooltip::after {
    border-right-color: white !important;
    border-right-style: solid !important;
    border-right-width: 6px !important;
}

.authorTooltipText {
    margin-left: 10px;
}

.authorTooltipHeader {
    display: flex;
    color: #111;
    align-items: center;
}

.authorTooltipName {
    font-size: 16px;
}

.authorTooltipBio {
    color: black;
}

.lowPriorityInfo {
    display: flex;
    align-items: center;
    color: rgb(117, 117, 117);
}

.lpLeft {
    margin-left: 8px;
}

.infoDivider {
    margin: 0 3px 0 4px;
}

.publishedDate {
    margin-right: 8px;
}

.authorTooltipAvatar {
    width: 38px !important;
    height: 38px !important;
}

.authorAvatarContainer {
    width: 28px;
    height: 28px;
    font-size: 1rem;
    display: flex;
    overflow: hidden;
    position: relative;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    line-height: 1;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    color: white;
    background-color: var(--primary-color);
}

.avatarImg {
    pointer-events: none;
    color: transparent;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: center;
    text-indent: 10000px;
}

.actionsSmAndUp {
    display: none;
}

.actionsXs {
    margin-top: 10px;
}

.share {
    height: 18px;
    width: 18px;
    cursor: pointer;
    color: rgb(56, 56, 56);
}

.shareTooltipXs {
    opacity: 1 !important;
    padding: 12px 20px 12px 12px !important;
    background-color: white !important;
    border: 1px solid rgb(230, 230, 230) !important;
    box-shadow: rgb(230, 230, 230) 0px 1px 4px;
}

.shareTooltipXs::before {
    border-right: 8px solid rgb(230, 230, 230) !important;
}

.shareTooltipXs::after {
    border-right-color: white !important;
    border-right-style: solid !important;
    border-right-width: 6px !important;
}

.shareTooltipSmAndUp {
    opacity: 1 !important;
    padding: 12px 20px 12px 12px !important;
    background-color: white !important;
    border: 1px solid rgb(230, 230, 230) !important;
    box-shadow: rgb(230, 230, 230) 0px 1px 4px;
}

.shareTooltipSmAndUp li {
    padding-left: 0 !important;
}

.shareTooltipSmAndUp li::before {
    display: none;
}

.shareTooltipXs li {
    padding-left: 0 !important;
}

.shareTooltipXs li::before {
    display: none;
}

.shareTooltipSmAndUp::before {
    border-left: 8px solid rgb(230, 230, 230) !important;
}

.shareTooltipSmAndUp::after {
    border-left-color: white !important;
    border-left-style: solid !important;
    border-left-width: 6px !important;
}

.shareTooltipBtnContainer {
    color: rgb(117, 117, 117);
    list-style: none;
    padding: 0;
    margin-left: 6px;
}

.shareTooltipBtnContainer li {
    cursor: pointer;
    padding: 6px 0;
    font-size: 16px;
}

.shareTooltipIcon {
    margin-right: 10px;
}

.shareTooltipBtnContent {
    display: flex;
    align-items: center;
}

.content {
    margin-bottom: 30px;
}

.content h1 + p,
.content h2 + p,
.content h3 + p,
.content h4 + p,
.content h5 + p,
.content h6 + p {
    margin-top: 0.65em;
}

.content p,
.content ol,
.content ul {
    letter-spacing: -0.003em;
    line-height: 28px;
    font-size: 18px;
    word-break: break-word;
    font-weight: 400;
    font-style: normal;
    font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
    margin-top: 2em;
    margin-bottom: -0.46em;
    color: rgb(41, 41, 41);
}

.content p b,
.content ol b,
.content ul b {
    font-weight: 600;
    letter-spacing: -0.003em;
}

.content ul,
.content ol {
    margin-top: 1.4em;
}

.content ul li:firs-child,
.content ol li:first-child {
    margin-top: 0;
}

.content ul li,
.content ol li {
    margin-top: 0.65em;
}

.content h1,
.content h2,
.content h3 {
    letter-spacing: 0;
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    font-style: normal;
    margin-top: 1.25em;
    margin-bottom: -0.28em;
}

.content h4,
.content h5,
.content h6 {
    letter-spacing: 0;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    font-style: normal;
    margin-top: 2em;
    margin-bottom: -0.28em;
}

.newsletterContainer {
    margin: 30px 0 100px 0;
}

.readMoreContainer {
    text-align: center;
}

.readMoreLink {
    color: rgb(117, 117, 117);
    text-decoration: none;
    padding: 8px 9px 4px 7px;
    transition: 240ms;
    border-radius: 50%;
    font-size: 14px;
}

.readMoreLink:hover {
    background: rgb(0, 0, 0, 0.075);
    color: black;
}

a {
    color: black;
    font-weight: 600;
}

.tableOfContents ul {
    display: table;
    margin: 0;
    padding: 0;
}

.tableOfContents ul ul li:first-child{
    margin-top: 10px !important;
    margin-left: 25px;
    padding-left: 20px;
}

.tableOfContents ul ul li {
    margin-top: 0 !important;
    margin-left: 25px;
    padding-left: 20px;
}

.tableOfContents li::before {
    content: unset;
}

.tableOfContents h4 {
    margin-top: 0;
}

/* SmAndUp */
@media only screen and (min-width: 600px) {
    .header h1 {
        letter-spacing: -0.011em;
        line-height: 56px;
        font-size: 46px;
    }

    .actionsXs {
        display: none;
    }

    .actionsSmAndUp {
        height: 18px;
        width: 18px;
        margin-left: auto;
        display: initial;
    }
}

@media only screen and (min-width: 795px) {
    .shareTooltipSmAndUp::before {
        border-left: 8px solid transparent !important;
        border-bottom: 8px solid rgb(230, 230, 230) !important;
    }

    .shareTooltipSmAndUp::after {
        border-left: 6px solid transparent !important;
        border-bottom-color: white !important;
        border-bottom-style: solid !important;
        border-bottom-width: 6px !important;
    }
}

/* MdAndUp */
@media only screen and (min-width: 960px) {
    .content p,
    .content ol,
    .content ul {
        line-height: 32px;
        font-size: 21px;
    }

    .content h1,
    .content h2,
    .content h3 {
        font-size: 30px;
        line-height: 36px;
    }

    .content h4,
    .content h5,
    .content h6 {
        font-size: 23px;
        line-height: 35px;
    }
}
