.background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.1);
}

.container {
    position: fixed;
    right: 0;
    background: white;
    max-width: 340px;
    height: 100%;
    padding: 14px 0;
    overflow-y: auto;
    z-index: 999;
    border-left: 2px solid rgb(211, 211, 211);
}

.containerHeader {
    display: flex;
    align-items: center;
    padding: 0 14px;
}

.containerHeader h2 {
    font-size: 1em;
    opacity: 0.8;
    margin-right: 20px;
}

.closeIcon {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
}

.contentDesc {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: .75em;
    text-align: justify;
    padding: 0 14px;
}

.cookieTypeList {
    margin-top: 10px;
    padding: 0;
}

.cookieType {
    padding: 0 !important;
    border-bottom: 1px solid rgb(211, 211, 211);
    margin-bottom: 14px;
}

.cookieType::before {
    display: none;
}

.cookieTypeHeader {
    display: flex;
    margin-bottom: 4px;
    padding: 0 14px;
}

.switch {
    margin-left: auto;
}

.cookieType p {
    font-size: .75em;
    line-height: 1.25;
    padding: 0 14px;
    text-align: justify;
    margin-bottom: 30px;
}

.cookieNoticeContainer {
    margin-top: 6px;
}

.cookieNoticeHeader {
    font-size: 0.8em;
    opacity: 0.4;
    cursor: pointer;
    padding: 0 14px;
    margin-bottom: 4px;
}

.cookieNoticeList {
    margin-top: 10px;
    padding-left: 0;
    background-color: #EEE;
}

.noItems {
    padding: 14px !important;
    font-weight: 600;
}

.submitContainer {
    padding: 10px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submitContainer button {
    width: 100%;
    padding: 6px;
    border-radius: 4px;
    border: 1.2px solid black;
    font-size: 0.8em;
    font-weight: 600;
    cursor: pointer;
    transition: 100ms;
}

.submitContainer button:hover {
    background-color:rgba(0, 0, 0, 0.1)
}
